<template lang="pug">
	.pixeltest-requests
		pw-btn.mb-3(:text="'Trial'" :rightText="$t('forms.send')" @click.native="send('trial')")
		pw-btn.mb-3(:text="'Firstbill'" :rightText="$t('forms.send')" @click.native="send('firstbill')")
		pw-btn.mb-3(:text="'Update'" :rightText="$t('forms.send')" @click.native="send('update')")
		pw-btn.mb-3(:text="'Rebill'" :rightText="$t('forms.send')" @click.native="send('rebill')")
		pw-btn.mb-3(:text="'Cancel'" :rightText="$t('forms.send')" @click.native="send('cancel')")
		pw-btn.mb-3(:text="'Refund'" :rightText="$t('forms.send')" @click.native="send('refund')")
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PwBtn from '@/components/Buttons/PwBtn';

export default {
    name: 'PixelTestRequests',
    components: {
        PwBtn,
    },
    computed: {
        ...mapGetters({
            item: 'project/opened',
        }),
    },
    methods: {
        ...mapActions({
            action: 'project/testWebhooks',
        }),
        send(type) {
            this.action({ id: this.item.id, type: type, clickId: '0000000000' })
                .then(v => {
                    this.$notify('success', this.$t('success.requestSended'));
                })
                .catch(v => {
                    this.$notify('error', this.$t('errors.sendingError'));
                });
        },
    },
};
</script>
