<template lang="pug">
	.delete-bot
		p.b3(v-html="$t('bots.deleteProjectNeurobot')")

		.mt-4
			a.d-block.mb-4.text-decoration-none(:href="`https://t.me/${$t('contacts.support_tg')}`" target="_blank")
				pw-btn(:text="$t('h1.telegram')" :icon="'icon-telegram'")
			pw-btn-mail
		
</template>

<script>
import PwBtn from '@/components/Buttons/PwBtn';
import PwBtnMail from '@/components/Buttons/PwBtnMail';

export default {
    name: 'DeleteBot',
    components: {
        PwBtn,
        PwBtnMail,
    },
};
</script>
